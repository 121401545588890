import {
  Backdrop, Box, Button, Checkbox, Chip, CircularProgress, ClickAwayListener, Dialog, DialogActions, DialogContent,
  DialogContentText, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, InputLabel, Paper,
  Select, Snackbar, TextField, Typography, useMediaQuery
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SearchIcon from "@material-ui/icons/Search";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import PuffLoader from "react-spinners/PuffLoader";
// Icons
import DoneAllIcon from '@mui/icons-material/DoneAll';
import InfoIcon from '@mui/icons-material/Info';
//components
import { useTheme } from '@material-ui/core/styles';
import { Autocomplete, AutocompleteCloseReason } from "@material-ui/lab";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { DialogTitle } from '@mui/material';
import React, { useEffect, useState } from "react";
import { Scrollbars } from 'react-custom-scrollbars';
import * as constant from "../../../constants/Constant";
import cloudfile from '../../../images/users/cloudfile.svg';
import LightTooltip from '../../GlobalStyles/LightTooltip';
//Models
import { AccessContext } from '../../../models/accesscontrol/AccessContext';
import { IUserInfo } from '../../../models/accesscontrol/IUserInfo';
import { IWidgetInfo } from '../../../models/accesscontrol/IWidgetInfo';
import { WidgetMap } from '../../../models/accesscontrol/WidgetMap';
import { IClientInfo } from '../../../models/client/IClientInfo';
import { IGetBlobFolders } from '../../../models/Files/IGetBlobFolders';
import { GlobalStateAction, useGlobalState } from '../../../store/GlobalStore';
import { useFetch, usePost } from '../../../utils/apiHelper';
import { DialogTitleDelete, DialogTitleHeader } from '../../GlobalStyles/DialogStyle';
import { useStyles } from './UsersCss';
import { Transition } from '../../GlobalStyles/DialogBoxTransition';
import { IGroupEmailClientCodeResult } from '../../../models/NotificationCenter/TrustStatementClientCodeResult';

const GA_EditUser: React.FC<{ userRow: IUserInfo, onSave: (userRow: IUserInfo) => void, onRemove: () => void, checkSuperAdmin: boolean, CloudContainer: IGetBlobFolders[], PaymentContainer: IGetBlobFolders[], AckContainer: IGetBlobFolders[] }> = (props) => {
  const userRow = props.userRow;
  const theme = useTheme();
  const classes = useStyles();
  const { state, dispatch } = useGlobalState();

  var newUser: AccessContext = new AccessContext();
  const [localState, setLocalState] = React.useState(newUser);

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [firstnameerror, setFirstNameError] = useState("");
  const [Lastnameerror, setLastNameError] = useState("");

  const [showFilePermisson, setShowFilePermission] = useState(false);
  const [ProgressBar, setShowProgressBar] = useState(false);
  const [ClientErrMsg, setClientErrMsg] = useState(false);
  const [showAdfLogs, setShowAdfLogs] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [submit, setSubmit] = React.useState(false);
  const [phone, setPhone] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [cloudFile, setCloudFile] = useState(false);

  const [selectedClients, setSelectedClients] = useState<any>([]);
  const [confirmedClients, setConfirmedClients] = React.useState([]);
  const [cloudFileFolderOpen, setCloudFileFolderOpen] = useState(false);
  const [paymentFileFolder, setPaymentFileFolder] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [ClientCodeOpen, setClientCodeOpen] = useState(false);
  const [searchClientCode, setSearchClientCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [deselectClient, setDeselectClient] = useState(false);
  const [fId, setFID] = useState(0);
  const [fileContains, setFileContains] = useState("");
  const [cloudfileError, setCloudfileError] = useState("");
  const [getFileContains, setGetFileContains] = useState(localState?.ftpFilesContains);
  const [displayFtpRegex, setDisplayFtpRegex] = useState(false);
  const [isCloudFolder, setIsCloudFolder] = useState<any>();
  const [groupEmailClientCodeList, setGroupEmailClientCodeList] = useState<IGroupEmailClientCodeResult[]>([]);

  const handleAlertClose = () => {
    setCloudFileFolderOpen(false);
    setPaymentFileFolder(false);
    setCloudFile(false);
  };

  const handleSearchClient = (e) => {
    setSearchClientCode(e.target.value);
  }

  const handleClickOpen = () => {
    GetClientCodeBySharedTrustStatement();

    (async () => {
      setOpen(true);
      setConfirmedClients([]);
      setLoading(true);
      setShowProgressBar(true);
      let request = {
        "userId": state?.userAccessContext?.id,
        "client_code": searchClientCode?.trim().toLocaleUpperCase(),
        "isAdmin": state?.userAccessContext.role
      }
      await useFetch<AccessContext>("User/GetUser?userId=" + userRow?.id).then(async (user) => {
        await useFetch<IWidgetInfo[]>("User/GetWidgetsForClient?userId=" + userRow?.id).then(async (widgets) => {
          for (let index = 1; index <= WidgetMap.size; index++) {
            let value = WidgetMap.get(index);
            if (value && widgets.data.filter(w => w.widgetId === index).length === 1) {
              user.data[value] = true;
            }
          };
          setLocalState(user.data);
          await usePost<IClientInfo[]>("Client/searchClientCode", request).then((clients) => {
            setConfirmedClients(clients.data);
            setLoading(false);
            {
              user?.data && !selectedClients?.length ?
                setSelectedClients(clients?.data.filter(p => {
                  return user?.data?.clientCodes?.filter(r => { return r === p.client_Code }).length > 0
                }))
                : null
            }
          });
        });
      }).finally(() => {
        setShowProgressBar(false);
        setCheckAll(false);
      });
    })();
  }

  const handleSearch = () => {
    (async () => {
      setConfirmedClients([]);
      setLoading(true);
      setShowProgressBar(true);
      setOpen(true);
      let request = {
        "userId": state?.userAccessContext?.id,
        "client_code": searchClientCode?.trim().toLocaleUpperCase(),
        "isAdmin": true
      }
      await usePost<IClientInfo[]>("Client/searchClientCode", request).then((r) => {
        setConfirmedClients(r.data);
        setLoading(false);
      }).finally(() => {
        setShowProgressBar(false);
        setCheckAll(false);
      });
    })()
  };

  const handleClear = () => {
    (async () => {
      setOpen(true);
      let request = {
        "userId": state?.userAccessContext?.id,
        "client_code": "",
        "isAdmin": state?.userAccessContext?.role
      }
      await usePost<IClientInfo[]>("Client/searchClientCode", request).then((r) => {
        setConfirmedClients(r.data);
      }).finally(() => {
        setShowProgressBar(false);
      });
    })()
  };

  const handleClose = () => {
    setOpen(false);
    setLocalState(newUser);
    setFirstNameError(null);
    setLastNameError(null);
    setClientErrMsg(false);
    setClientCodeOpen(false);
    setCheckAll(false);
    setSearchClientCode("");
    setConfirmedClients([]);
    setSelectedClients([]);
    setCloudfileError("");
    setFileContains("");
    setGetFileContains("");
    setDisplayFtpRegex(false);
    setIsCloudFolder([]);
  };

  const handleCloudDialogCLose = () => {
    setCloudFile(false);
  }

  const handleSave = () => {
    (async () => {
      dispatch({ type: GlobalStateAction.Busy });
      setShowProgressBar(true);
      let user = localState;
      user.clientCodes = selectedClients.map(r => r.client_Code);
      if (getFileContains !== "") {
        let finalExpression = "";
        if (!constant?.valComma.test(getFileContains)) {
          finalExpression = fileContains?.replace("µ", getFileContains?.toUpperCase());
        } else {
          finalExpression = fileContains?.replace("µ", getFileContains?.toUpperCase()?.replaceAll(',', '|'));
        }
        localState.ftpRegex = finalExpression;
      } else if (localState?.ftpFilesContains?.length) {
        let finalRegexExpression = "";
        finalRegexExpression = fileContains?.replace("µ", localState?.ftpFilesContains?.toUpperCase());
        localState.ftpRegex = finalRegexExpression;
      } else {
        localState.ftpRegex = "";
      }

      if (selectedClients.length <= 0) {
        setClientErrMsg(true);
        setShowProgressBar(false);
      } else {
        await usePost("User/SaveUser", localState).then(async (UserDetails) => {
          if (UserDetails.status == 400) {
            dispatch({ type: GlobalStateAction.Error, error: UserDetails.statusText });
            dispatch({ type: GlobalStateAction.Idle });
            setShowProgressBar(false);
          }
          else if (UserDetails.status == 200) {
            let selectedWidgets: number[] = [];
            for (let index = 1; index <= WidgetMap.size; index++) {
              var value = WidgetMap.get(index);
              if (value && localState[value]) {
                selectedWidgets.push(index);
              }
            }

            let data = { 'userId': localState.id, 'widgets': selectedWidgets.join(',') };
            await usePost("User/AddWidgetsForClient", data).then((ClientWidgets) => {
              if (ClientWidgets.status == 400) {
                dispatch({ type: GlobalStateAction.Error, error: ClientWidgets.statusText });
                dispatch({ type: GlobalStateAction.Idle });
                setShowProgressBar(false);
              }
              else if (ClientWidgets.status == 200) {
                props.onSave(localState);
                setShowProgressBar(false);
                dispatch({ type: GlobalStateAction.Idle });
                setSubmit(true);
              }
              dispatch({ type: GlobalStateAction.Idle });
            });
          }
        }).finally(() => {
          setShowProgressBar(false);
          setOpen(false);
          setDisplayFtpRegex(false);
          setGetFileContains("");
          setIsCloudFolder([]);
          handleClose();
        });
      }
    })();
  };

  const handleChange = (event) => {
    const name = event.target.name;
    let userRole = (event.target.value == 3 ? "Super Admin" : "Global Admin");
    if (event.target.value == 3 || event.target.value == 2) {
      if (confirm('Are you sure you want to make this user as ' + userRole)) {
        setLocalState({ ...localState, [name]: event.target.value, });
      } else {
        setLocalState({ ...localState, [name]: userRow.global_admin, });
      }
    } else {
      setLocalState({ ...localState, [name]: event.target.value, });
    }
  };

  const regexBuilder = (e) => {
    let fileText = e?.target?.value;
    if (constant?.FileContainRegex.test(fileText)) {
      setCloudfileError("");
    } else {
      setCloudfileError("File Contains must be characters,numbers and without space");
    }
  }

  const handleChecked = (event) => {
    const name = event.target.name;
    setLocalState({
      ...localState,
      [name]: event.target.checked,
    });
  };

  const HandleFirstNameVal = (e) => {
    const FirstNameVal = e.target.value;
    if (!FirstNameVal.match(constant.firstNameregex)) {
      setFirstNameError("");
      setIsDisable(false);
    } else if (FirstNameVal === "") {
      setFirstNameError("First Name is Required");
      setIsDisable(true);
    } else {
      setFirstNameError("please enter characters only");
      setIsDisable(true);
    }
  };

  const HandleLastNameVal = (e) => {
    const LastNameVal = e.target.value;
    if (!LastNameVal.match(constant.lastNameregex)) {
      setLastNameError("");
      setIsDisable(false);
    } else if (LastNameVal === "") {
      setLastNameError("Last Name is Required");
      setIsDisable(true);
    }
    else {
      setLastNameError("please enter characters only");
      setIsDisable(true);
    }
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const closeSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') { return; }
    setSubmit(false);
    setPhone(false);
    setClientErrMsg(false);
  };

  useEffect(() => {
    if (localState?.ftpFolder === "Pest Pac") {
      setFileContains(props?.CloudContainer[1]?.fileContains);
    } else if (localState?.ftpFolder === "Pest Routes") {
      setFileContains(props?.CloudContainer[2]?.fileContains);
    } else if (localState?.ftpFolder === "Service Pro") {
      setFileContains(props?.CloudContainer[3]?.fileContains);
    } else if (localState?.ftpFolder === "Rccbi") {
      setFileContains(props?.CloudContainer[5]?.fileContains);
    } else if (localState?.ftpFolder === "Blue Cow") {
      setFileContains(props?.CloudContainer[4]?.fileContains);
    }
  }, [localState?.ftpFolder])

  async function onContainerSelect(event, value) {
    if (value?.folder_Name !== localState?.ftpFolder) {
      setCloudFile(true);
    } else {
      setCloudFile(false);
    }
  }

  const HandleUpdateContainer = (event, value) => {
    setCloudFile(false);
    setFileContains(value?.fileContains);
    setFID(value?.folder_Id);
    setDisplayFtpRegex(true);
    setLocalState({ ...localState, ftpFolder: value?.folder_Name });
    if ((value?.fileContains === null || value?.fileContains === undefined) || localState?.ftpFilesContains?.length > 0) {
      setCloudfileError("");
    } else {
      setCloudfileError("File Contains must be characters,numbers and without space");
    }
  }

  async function onPaymentFileSelect(event, value) {
    setLocalState({ ...localState, paymentFileFolder: value["containerName"] });
  }

  const handleChangeshowpermission = (event) => {
    setShowFilePermission(event.target.checked);
  }

  const handleChangeShowAdfLogs = (event) => {
    setShowAdfLogs(event.target.checked);
  }

  useEffect(() => {
    if (localState.ftpFolder === "None") {
      setLocalState({ ...localState, ftpFolder: null });
    } else if (localState.paymentFileFolder === "None") {
      setLocalState({ ...localState, paymentFileFolder: null });
    } else if (localState.ackFileFolder === "None") {
      setLocalState({ ...localState, ackFileFolder: null });
    }
  }, [localState])

  const handleClientSelect = (event, selected) => {
    setSelectedClients(selected);
    if (selected?.length <= 0) {
      setCheckAll(false);
    }
  }

  const checkAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckAll(event.target.checked);
    if (event.target.checked) {
      var NewClientList = confirmedClients.filter((c) => {
        return !selectedClients.find((r) => {
          return c.client_Code == r.client_Code
        })
      });
      setSelectedClients([...new Set(selectedClients?.concat(NewClientList?.map((r) => r)))]);
      setClientCodeOpen(false);
    }
    setCheckAll(false);
    handleClear();
    setSearchClientCode("");
  };

  const handleClickAway = (e) => {
    (async () => {
      setClientCodeOpen(false);
      let request = {
        "userId": state?.userAccessContext?.id,
        "client_code": "",
        "isAdmin": true
      }
      await usePost<IClientInfo[]>("Client/searchClientCode", request).then((r) => {
        setConfirmedClients(r.data);
      }).finally(() => {
        setShowProgressBar(false);
      });
    })()
  };

  const DeselectAllChange = (event) => {
    setDeselectClient(event.target.checked);
    if (event.target.checked) {
      var NewClientList = selectedClients.filter((c) => {
        return !confirmedClients.find((r) => {
          return c.client_Code == r.client_Code
        })
      });
      setSelectedClients(NewClientList);
      setClientCodeOpen(false);
    }
    setDeselectClient(false);
    handleClear();
    setSearchClientCode("");
  };

  const onDelete = (clientcode) => () => {
    setSelectedClients((value) => value.filter((v) => v.client_Code !== clientcode));
  };

  const GetClientCodeBySharedTrustStatement = () => {
    setShowProgressBar(true);
    (async () => {
      await useFetch<IGroupEmailClientCodeResult[]>("GetClientCodeBySharedTrustStatement?EmailAddress=" + userRow?.userName).then((r) => {
        setGroupEmailClientCodeList(r?.data);
      })
    })().finally(() => {
      setShowProgressBar(false);
    })
  }

  return (
    <React.Fragment>
      <div>
        <Button size="small" id="EU_Edit" startIcon={<BorderColorIcon />} variant="contained" color="primary" onClick={handleClickOpen} className={classes.editButton} disabled={props.checkSuperAdmin && state.userAccessContext?.role === 2} >
          Edit
        </Button>
      </div>
      <Dialog TransitionComponent={Transition}
        PaperProps={{ style: { borderRadius: 15, maxHeight: 'calc(100% - 7px)' } }}
        className={classes.paperScroll}
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        fullWidth={true}
        maxWidth={'xl'}
      >
        <DialogTitleHeader id="responsive-dialog-title" onClose={handleClose}>
          <Typography variant="h6" className={classes.EditTextStyle}>
            EDIT USER
          </Typography>
        </DialogTitleHeader>
        <Backdrop className={classes.backdrop} open={ProgressBar}>
          <PuffLoader size={80} color={"white"} speedMultiplier={1} />
        </Backdrop>
        <DialogContent>
          <Grid container spacing={0} className={`${classes.grid} edit-modal`}>
            <Grid className={classes.colPadding} item xs={12} sm={6} md={3} >
              <Box className={classes.userboxviewStyle}>
                <Typography variant="h6" gutterBottom className={`${classes.root} ${classes.userStyle}`}>{"USER PROFILE"}</Typography>
                <form className={`${classes.root} ${classes.formFlex}`} noValidate autoComplete="off" >
                  <TextField id="EU_firstname" size="small" value={localState.firstName} label="First Name" variant="outlined" name="firstName" onChange={(e) => { handleChange(e); HandleFirstNameVal(e); }} helperText={firstnameerror} error={!!firstnameerror}
                    inputProps={{ maxlength: 25 }} className={`${classes.textFieldBorder} ${classes.firstnameInputSpacing}`}
                    InputProps={{
                      classes: {
                        root: classes.blueBorderBottom,
                      },
                    }} />
                  <TextField id="EU_lastname" size="small" value={localState.lastName} label="Last Name" variant="outlined" name="lastName" onChange={(e) => { handleChange(e); HandleLastNameVal(e); }} helperText={Lastnameerror} error={!!Lastnameerror}
                    inputProps={{ maxlength: 25 }} className={`${classes.textFieldBorder} ${classes.lastnameInputSpacing}`}
                    InputProps={{
                      classes: {
                        root: classes.blueBorderBottom,
                      },
                    }} />
                </form>
                <form className={classes.root} noValidate autoComplete="off" >
                  <TextField id="EU_Username" size="small" value={localState.userName} label="Email"
                    disabled={true} variant="outlined" name="userName"
                    className={classes.textFieldBorder}
                    InputProps={{
                      classes: {
                        root: classes.blueBorderBottom,
                      },
                    }} />
                </form>
                <form className={classes.root} noValidate autoComplete="off" >
                  <FormControl size="small" variant="outlined" className={`${classes.formControlCtEt} ${classes.adminBorderBottom}`}>
                    <InputLabel htmlFor="outlined-age-native-simple">Admin Level</InputLabel>
                    <Select native
                      id="EU_AdminLevel"
                      onChange={handleChange}
                      value={localState.role}
                      label="Admin Level"
                      inputProps={{
                        name: 'role',
                        id: 'outlined-age-native-simple'
                      }}
                    >
                      <option value={0}>Regular User</option>
                      <option value={1}>Client Admin</option>
                      <option value={2}>Global Admin</option>
                      {state.userAccessContext?.role === 3 ?
                        <React.Fragment>
                          <option value={3}>Super Admin</option>
                          <option value={4}>File Admin</option>
                        </React.Fragment>
                        : null}
                    </Select>
                  </FormControl>
                </form>
              </Box>
              {groupEmailClientCodeList[0]?.clientCode != "User not found" ?
                <Box m={0} mt={1} padding={"3px"} ml={"8px"} mr={"8px"} borderRadius={"5px"} bgcolor={'hsl(48deg 100% 96% / 50%)'} border={'1px solid hsl(36deg 100% 34% / 15%)'} >
                  <Typography variant="body2" style={{ marginLeft: '10px', color: 'black' }}>
                    <b>Shared Trust Statement FIle Client Code</b>
                  </Typography>
                  <Scrollbars autoHide
                    autoHideTimeout={1000}
                    autoHideDuration={10}
                    autoHeight
                    autoHeightMin="auto"
                    autoHeightMax={80}
                    style={{ width: '100%' }}>
                    <Typography variant="body2" style={{ marginLeft: '10px', marginTop: '3px', color: 'hsl(36, 100%, 27%)' }}>
                      {groupEmailClientCodeList?.flatMap(r => r.clientCode).join(', ')}
                    </Typography>
                  </Scrollbars>
                </Box>
                : null
              }
              <Box className={classes.userboxviewStyle}>
                <div >
                  <Box className={classes.LabelBoxStyle}>
                    <Typography variant="body1" className={classes.formLabel}>Selected Client Code : {selectedClients.length}</Typography>
                  </Box>
                </div>
                <div >
                  {selectedClients?.length > 0 ?
                    <Paper className={classes.boxBorder}>
                      <Scrollbars autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={10}
                        autoHeight
                        className={classes.textBoxStyle2}
                        autoHeightMin="auto"
                        autoHeightMax={120}
                        style={{ width: '100%' }}>
                        <div className={`${classes.valueContainer}`}>
                          {selectedClients.map((v) => (
                            <Chip key={v.client_Code} size="small" color="secondary" label={v.client_Code} onDelete={onDelete(v.client_Code)} />
                          ))}
                        </div>
                      </Scrollbars>
                    </Paper>
                    :
                    null
                  }
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <Box>
                      <Autocomplete
                        multiple size="small"
                        loading={loading}
                        filterOptions={(options, state) => options}
                        className={classes.clientCodeBox}
                        autoComplete={false}
                        id="checkboxes-tags-demo"
                        options={confirmedClients}
                        classes={{ paper: classes.paper2 }}
                        disableClearable
                        value={selectedClients}
                        open={ClientCodeOpen}
                        renderTags={() => null}
                        onChange={(e, selected) => { handleClientSelect(e, selected); }}
                        onOpen={() => {
                          setClientCodeOpen(true);
                        }}
                        onClose={(e: any, reason: AutocompleteCloseReason) => {
                          if (reason === "toggleInput") {
                            setClientCodeOpen(false);
                          }
                        }}
                        getOptionDisabled={(option) => option.client_Code}
                        getOptionLabel={(option) => option.client_Code}
                        getOptionSelected={(option, value) => option.client_Code == value.client_Code}
                        renderOption={(option, { selected }) => (
                          <span className={`${selected ? classes.GreenColor : null}`} style={{ fontSize: 13, marginLeft: '5px', width: 200 }}>
                            {option.client_Code}
                          </span>
                        )}
                        ListboxProps={
                          {
                            style: {
                              maxHeight: '160px'
                            }
                          }
                        }
                        renderInput={(params) => (
                          <TextField {...params} fullWidth size="small"
                            value={searchClientCode} variant="outlined"
                            type='text' placeholder="Search Clients"
                            onChange={(e) => { handleSearchClient(e) }}
                            onKeyDown={event => {
                              if (event.key === 'Enter') { handleSearchClient(event); handleSearch(); setClientCodeOpen(true); }
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {loading ? <CircularProgress color="primary" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                  <InputAdornment position="end">
                                    <IconButton onClick={(_) => { handleSearch(); setClientCodeOpen(true); }}>
                                      <SearchIcon className={classes.IconSizeStyle} />
                                    </IconButton>
                                  </InputAdornment>
                                  <Box display="flex" width="100%" className={classes.CheckBoxBorder}>
                                    <Box className={classes.checkBoxStyle} width="60%">
                                      <Checkbox size="small" disabled={loading || !confirmedClients.length}
                                        checked={checkAll}
                                        onChange={checkAllChange}
                                        id="check-all"
                                      />
                                      <span style={{ fontSize: 12 }}>Select All</span>
                                    </Box>
                                    <Box className={classes.checkBoxStyle} width="40%">
                                      <Checkbox size="small" disabled={loading || !confirmedClients.length}
                                        checked={deselectClient}
                                        onChange={(e) => { DeselectAllChange(e); }}
                                        id="check-all"
                                      />
                                      <span style={{ fontSize: 12 }}>Deselect All</span>
                                    </Box>
                                  </Box>
                                </React.Fragment>
                              ),
                            }}
                            className={classes.textBoxStyle1}
                          />
                        )}
                      />
                    </Box>
                  </ClickAwayListener>
                </div>
              </Box>
              {state.userAccessContext?.role === 3 && localState.role == 3 ?
                <React.Fragment>
                  <Box className={`${classes.boxview}`} >
                    <Typography variant="h6" gutterBottom className={classes.dialogHeaderStyle}>{"ADMIN PERMISSIONS"}</Typography>
                    <FormControl component="fieldset" >
                      <FormGroup>
                        <Box display="flex">
                          <Box m={0}>
                            {state.userAccessContext?.role === 3 ?
                              <FormControlLabel className={classes.checkBoxStyle}
                                id="U_CU_IsGlobalSettings"
                                control={<Checkbox size="small" onChange={(e) => { handleChecked(e); handleChangeShowAdfLogs(e); }} name="isGlobalSettings" checked={localState.isGlobalSettings} />}
                                label="Global Settings"
                              /> : null}
                            {showAdfLogs && state.userAccessContext?.role === 3 || localState.isGlobalSettings ?
                              <React.Fragment>
                                <FormControlLabel className={classes.checkBoxStyle}
                                  id="U_CU_IsAdfLogs"
                                  control={<Checkbox size="small" onChange={handleChecked} name="isAdfLogs" checked={localState.isAdfLogs} />}
                                  label="ADF Logs"
                                />
                              </React.Fragment> : null}
                            {showAdfLogs && state.userAccessContext?.role === 3 || localState.isGlobalSettings ?
                              <React.Fragment>
                                <FormControlLabel className={classes.checkBoxStyle}
                                  id="U_CU_IsAzureFunc"
                                  control={<Checkbox size="small" onChange={handleChecked} name="isAzureFunc" checked={localState.isAzureFunc} />}
                                  label="Azure Functions"
                                />
                                <FormControlLabel className={classes.checkBoxStyle}
                                  id="U_CU_IsUserLoginStatus"
                                  control={<Checkbox size="small" onChange={handleChecked} name="isUserLoginStatus" checked={localState.isUserLoginStatus} />}
                                  label="User Login Status"
                                />
                              </React.Fragment>
                              : null}
                          </Box>
                        </Box>
                      </FormGroup>
                    </FormControl>
                  </Box>
                </React.Fragment>
                : null}
              {showFilePermisson || localState.isFileAccess === true ?
                <React.Fragment>
                  <Box className={classes.boxview2}>
                    <Typography variant="h6" gutterBottom className={classes.dialogHeaderStyle}>{"FILE PERMISSIONS"}</Typography>
                    <FormControl component="fieldset">
                      <FormGroup>
                        <Box display="flex">
                          <Box m={0} width="50%">
                            <FormControlLabel className={classes.checkBoxStyle}
                              id="U_CU_UploadFile"
                              control={<Checkbox size="small" onChange={handleChecked} checked={localState.uploadfile} name="uploadfile" />}
                              label="Upload"
                            />
                            <FormControlLabel className={classes.checkBoxStyle}
                              id="U_CU_Download"
                              control={<Checkbox size="small" onChange={handleChecked} checked={localState.downloadfile} name="downloadfile" />}
                              label="Download"
                            />
                          </Box>
                          <Box m={0} width="50%">
                            <FormControlLabel className={classes.checkBoxStyle}
                              id="U_CU_Create" disabled={(localState.role != 3)}
                              control={<Checkbox size="small" onChange={handleChecked} checked={(localState.role == 3) ? localState.createfolder : localState.createfolder = false} name="createfolder" />}
                              label="Create Folder"
                            />
                          </Box>
                        </Box>
                      </FormGroup>
                    </FormControl>
                  </Box>
                </React.Fragment>
                : null}
            </Grid>
            <Grid className={classes.colPadding} item xs={12} sm={6} md={3} >
              <Typography variant="h6" gutterBottom className={classes.FileFolderStyle}>{"FOLDER PERMISSIONS"}</Typography>
              <Box className={`${!cloudfileError && fileContains?.length > 0 ? classes.boxviewStyle : cloudfileError ? classes.boxviewStyle2 : classes.boxviewStyle1}`}>
                <Typography variant="body1" gutterBottom className={classes.FileFolderStyle}>
                  Cloud File Folder
                  <LightTooltip title="View Regex for Cloud File Folder">
                    <IconButton color="secondary" aria-label="upload picture" component="span" onClick={() => { setCloudFileFolderOpen(true) }}>
                      <InfoIcon style={{ fontSize: 18 }} />
                    </IconButton>
                  </LightTooltip>
                </Typography>
                <Autocomplete
                  classes={{
                    paper: classes.paper1,
                    option: classes.fontSmall
                  }}
                  id="U_CU_Cloud_Dropdown"
                  options={props.CloudContainer}
                  className={classes.dropdownStyle}
                  value={localState.ftpFolder}
                  onChange={(e, val) => { onContainerSelect(e, val); setIsCloudFolder(val); }}
                  loading={true} disableClearable={true}
                  getOptionLabel={(option) => option.folder_Name || localState.ftpFolder}
                  getOptionSelected={(option, value) => option.folder_Name === value.folder_Name}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Cloud Folder" size="small" margin="normal" variant="outlined" name="ftpFolder"
                      className={classes.cloudFolderStyle}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                        classes: {
                          root: classes.blackBorderBottom,
                        }
                      }}
                    />
                  )}
                />
                {displayFtpRegex === true ?
                  (fId === 4 ?
                    <div className={classes.regexStyle}>
                      <Typography variant="body2" className={classes.regexText}> Sample Format : &nbsp;{constant?.exampeTwo} </Typography>
                    </div>
                    :
                    <div className={classes.regexStyle}>
                      <Typography variant="body2" className={classes.regexText}>  Sample Format : {constant?.exampleOne} </Typography>
                    </div>
                  )
                  :
                  null
                }
                {localState?.ftpFilesContains !== "" && localState?.ftpFilesContains !== null ?
                  <Typography variant="body2" style={{ wordBreak: 'break-all' }} className={classes.regexText1}> Updated Format : &nbsp;{localState?.ftpRegex}</Typography>
                  : null
                }
                <TextField className={classes.fileContainsStyle1} size="small" label="Cloud File Contains" variant="outlined" name="ftpFilesContains" value={localState.ftpFilesContains}
                  helperText={cloudfileError} error={!!cloudfileError} disabled={fId === -1 ? true : false} onChange={(e) => { regexBuilder(e); handleChange(e); setGetFileContains(e.target.value); }}
                  InputProps={{
                    classes: {
                      root: classes.blueBorderBottom,
                    },
                  }}
                />
                <Typography variant="body2" className={classes.fileNote}><b> Note :  Please enter the files contains with comma separated values.</b></Typography>
              </Box>
              <Box className={classes.boxview4}>
                <Typography variant="body2" gutterBottom className={classes.FileFolderStyle}>
                  Payment File Folder
                  <LightTooltip title="View Regex for Payment File Folder">
                    <IconButton color="secondary" aria-label="upload picture" component="span" onClick={() => { setPaymentFileFolder(true) }}>
                      <InfoIcon style={{ fontSize: 18 }} />
                    </IconButton>
                  </LightTooltip>
                </Typography>
                <Autocomplete
                  classes={{ paper: classes.paper1 }}
                  id="U_CU_Payment_Dropdown"
                  className={classes.dropdownStyle}
                  options={props.PaymentContainer} value={localState.paymentFileFolder}
                  onChange={onPaymentFileSelect}
                  loading={true} disableClearable={true}
                  getOptionLabel={(option) => option.folder_Name || localState.paymentFileFolder}
                  getOptionSelected={(option, value) => option.folder_Name === value.folder_Name}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Payment Folder" size="small" margin="normal" variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                        classes: {
                          root: `${classes.blackBorderBottom} ${classes.blackBorderBottomPayment} `,
                        }
                      }}
                    />
                  )}
                />
                <TextField className={classes.paymentFileContainsStyle} label="Payment File Contains" size="small" variant="outlined" name="paymentRegex" value={localState.paymentRegex} onChange={(e) => { handleChange(e); }}
                  InputProps={{
                    classes: {
                      root: classes.blueBorderBottom,
                    }
                  }} />
              </Box>
              <Box className={classes.boxviewWidgetsNotifications}>
                <Typography variant="h6" gutterBottom className={classes.FileFolderStyle}> {"AGENT USER PERMISSIONS"} </Typography>
                <FormControl component="fieldset" >
                  <FormGroup >
                    <FormControlLabel
                      id="U_CU_FileAccess"
                      className={classes.permission}
                      control={<Checkbox size="small" onChange={(e) => { handleChecked(e); }} name="agentCreateUser" />} checked={localState.agentCreateUser}
                      disabled={localState?.role < 2 || localState.role == null} label="Create Agent User"
                    />
                    <FormControlLabel
                      id="U_CU_FileAccess"
                      className={classes.permission}
                      control={<Checkbox size="small" onChange={(e) => { handleChecked(e); }} name="agentEditReport" />} checked={localState.agentEditReport}
                      disabled={localState?.role < 2 || localState.role == null} label="Edit Agent Report"
                    />
                    <FormControlLabel
                      id="U_CU_FileAccess"
                      className={classes.permission}
                      control={<Checkbox size="small" onChange={(e) => { handleChecked(e); }} name="agentReadOnlyAccess" />} checked={localState.agentReadOnlyAccess}
                      disabled={localState?.role < 2 || localState.role == null} label="Agent Read Only Access"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
            </Grid>
            <Grid className={classes.colPadding} item xs={12} sm={6} md={3} >
              <Box className={classes.boxviewWidgetsNotifications}>
                <Typography variant="h6" gutterBottom className={classes.FileFolderStyle}> {"PAYMENT PERMISSIONS"} </Typography>
                <FormControl component="fieldset" >
                  <FormGroup >
                    <FormControlLabel className={classes.permission}
                      id="EU_paymentUpdates"
                      control={<Checkbox size="small" onChange={handleChecked} checked={localState.paymentUpdates} name="paymentUpdates" />}
                      label="Payment Updates"
                    />
                    <FormControlLabel className={classes.permission}
                      id="EU_closingAccounts"
                      control={<Checkbox size="small" onChange={handleChecked} checked={localState.closingAccounts} name="closingAccounts" />}
                      label="Close Accounts"
                    />
                    <FormControlLabel className={classes.permission}
                      id="EU_rollAccounts"
                      control={<Checkbox size="small" onChange={handleChecked} checked={localState.rollAccounts} name="rollAccounts" />}
                      label="Roll Accounts"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Box className={classes.boxviewWidgetsNotifications}>
                <Typography variant="h6" gutterBottom className={classes.FileFolderStyle}> {"PLACEMENT PERMISSIONS"} </Typography>
                <FormControl>
                  <FormGroup>
                    <FormControlLabel className={classes.permission}
                      id="EU_placeAccounts"
                      control={<Checkbox size="small" onChange={handleChecked} checked={localState.placeAccounts ? true : false} name="placeAccounts" />}
                      label="Place Accounts"
                    />
                    <FormControlLabel className={classes.permission}
                      id="EU_uploadUnmappedFile"
                      control={<Checkbox size="small" onChange={handleChecked} checked={localState.uploadUnmappedFile} name="uploadUnmappedFile" />}
                      label="Upload Unmapped File"
                    />
                    <FormControlLabel className={classes.permission}
                      id="EU_multiplePlacementAccess"
                      control={<Checkbox size="small" onChange={handleChecked} name="multiplePlacementAccess"
                        checked={localState.multiplePlacementAccess} />}
                      label="Multi-Phase Placement Access"
                    />
                    <FormControlLabel className={classes.permission}
                      id="EU_multiClientCodePlacementAccess"
                      control={<Checkbox size="small" onChange={handleChecked} name="multiClientCodePlacementAccess"
                        checked={(localState.role == null || localState.role == 0 || localState.role == 4) ? localState.multiClientCodePlacementAccess = false : localState.multiClientCodePlacementAccess}
                        disabled={localState.role == null || localState.role == 0 || localState.role == 4} />}
                      label="Multi-Client Code Placement Access"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Box className={classes.boxviewWidgetsNotifications}>
                <Typography variant="h6" gutterBottom className={classes.FileFolderStyle}> {"OTHER PERMISSIONS"} </Typography>
                <FormControl>
                  <FormGroup>
                    <FormControlLabel className={classes.permission}
                      id="EU_editReports"
                      control={<Checkbox size="small" onChange={handleChecked} checked={localState.editReports ? true : false} name="editReports" />}
                      label="Edit Reports"
                    />
                    <FormControlLabel className={classes.permission}
                      id="EU_createNewUsers"
                      control={<Checkbox size="small" onChange={handleChecked} checked={localState.createNewUsers} name="createNewUsers" />}
                      label="Create New Users"
                    />
                    <FormControlLabel className={classes.permission}
                      id="EU_sendEmailOnAttachements"
                      control={<Checkbox size="small" onChange={handleChecked}
                        name="sendEmailOnAttachements" checked={localState.sendEmailOnAttachements} />}
                      label="Send Email on attachments"
                    />
                    <FormControlLabel className={classes.permission}
                      id="EU_SendEmailToAdmin"
                      control={<Checkbox size="small" onChange={handleChecked} name="sendEmailtoAdmin"
                        checked={localState.sendEmailOnAttachements ? localState.sendEmailtoAdmin : localState.sendEmailtoAdmin = false}
                        disabled={!localState.sendEmailOnAttachements} />}
                      label="Send Email To Admin"
                    />
                    <FormControlLabel className={classes.permission}
                      id="EU_clientConfirmation"
                      control={<Checkbox size="small" onChange={handleChecked} name="clientConfirmation" checked={localState.clientConfirmation} />}
                      label="Client Confirmation"
                    />
                    <FormControlLabel className={classes.permission}
                      id="EU_FileAccess"
                      control={<Checkbox size="small"
                        onChange={(e) => { handleChecked(e); handleChangeshowpermission(e) }} name="isFileAccess"
                        checked={(localState.role != 3 || localState.role == null) ? localState.isFileAccess = false : localState.isFileAccess}
                        disabled={localState.role != 3 || localState.role == null}
                      />}
                      label="Manage File Access"
                    />
                    <FormControlLabel
                      id="U_CU_AdditionalChargesReport"
                      className={classes.permission}
                      control={<Checkbox size="small" onChange={(e) => { handleChecked(e); }} checked={localState.additionalChargesReport} name="additionalChargesReport" />
                      }
                      label="Additional Charges Acknowledgment Report"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
            </Grid>
            <Grid className={classes.colPadding} item xs={12} sm={6} md={3} >
              <Box className={classes.boxviewWidgetsNotifications}>
                <Typography variant="h6" gutterBottom className={classes.FileFolderStyle}>{"NOTIFICATIONS"}</Typography>
                <FormControl component="fieldset" >
                  <FormGroup>
                    <FormControlLabel className={classes.permission}
                      id="EU_disputeValidationNotification"
                      control={<Checkbox size="small" onChange={handleChecked} name="disputeValidationNotification" checked={localState.disputeValidationNotification} />}
                      label="Dispute Validation Notification"
                    />
                    <FormControlLabel className={classes.permission}
                      id="EU_paymentVerificationNotification"
                      control={<Checkbox size="small" onChange={handleChecked} name="paymentVerificationNotification" checked={localState.paymentVerificationNotification} />}
                      label="Payment Verification Notification"
                    />
                    <FormControlLabel className={classes.permission}
                      id="EU_fileIssuesNotification"
                      control={<Checkbox size="small" onChange={handleChecked} name="fileIssuesNotification" checked={localState.fileIssuesNotification} />}
                      label="File Issues Notification"
                    />
                    <FormControlLabel className={classes.permission}
                      id="EU_backupDocumentRequestNotification"
                      control={<Checkbox size="small" onChange={handleChecked} name="backupDocumentRequestNotification" checked={localState.backupDocumentRequestNotification} />}
                      label="Backup Document Request Notification"
                    />
                    <FormControlLabel className={classes.permission}
                      id="EU_settlementRequestsNotification"
                      control={<Checkbox size="small" onChange={handleChecked} name="settlementRequestsNotification" checked={localState.settlementRequestsNotification} />}
                      label="Settlement Requests Notification"
                    />
                    <FormControlLabel className={classes.permission}
                      id="EU_generalNotification"
                      control={<Checkbox size="small" onChange={handleChecked} name="generalNotification" checked={localState.generalNotification} />}
                      label="General Notifications"
                    />
                    <FormControlLabel className={classes.permission}
                      id="EU_RollReportNotification"
                      control={<Checkbox size="small" onChange={handleChecked} name="rollReportNotification" checked={localState.rollReportNotification} />}
                      label="Roll Report Notification"
                    />
                    <FormControlLabel className={classes.permission}
                      id="U_CU_AckReportNotification"
                      control={<Checkbox size="small" onChange={handleChecked} name="ackReportNotification" checked={localState.ackReportNotification} />}
                      label="Acknowledgement Report Notification"
                    />
                    <FormControlLabel className={classes.permission}
                      id="U_CU_trustStatement"
                      control={<Checkbox size="small" onChange={handleChecked} name="trustStatement" checked={localState.trustStatement} />}
                      label="Trust Statement"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
              <Box className={classes.boxviewWidgetsNotifications}>
                <Typography variant="h6" gutterBottom className={classes.FileFolderStyle}>{"WIDGETS"}</Typography>
                <FormControl component="fieldset" >
                  <FormGroup>
                    <FormControlLabel className={classes.permission}
                      id="EU_flatFeeAcRem"
                      control={<Checkbox size="small" onChange={handleChecked} name="flatFeeAcRem" checked={localState.flatFeeAcRem} />}
                      label="Flat Fee A/C Rem"
                    />
                    <FormControlLabel className={classes.permission}
                      id="EU_acWaitingtoMove"
                      control={<Checkbox size="small" onChange={handleChecked} checked={localState.acWaitingtoMove} name="acWaitingtoMove" />}
                      label="A/C waiting to move"
                    />
                    <FormControlLabel className={classes.permission}
                      id="EU_daysSincePlacmentofAc"
                      control={<Checkbox size="small" onChange={handleChecked} checked={localState.daysSincePlacmentofAc} name="daysSincePlacmentofAc" />}
                      label="Days Since Placement of A/C"
                    />
                    <FormControlLabel className={classes.permission}
                      id="EU_placementHistoryofMonthAc"
                      control={<Checkbox size="small" onChange={handleChecked} checked={localState.placementHistoryofMonthAc} name="placementHistoryofMonthAc" />}
                      label="Placement History of 6 Month A/C"
                    />
                    <FormControlLabel className={classes.permission}
                      id="EU_ageImpactofonAcResolution"
                      control={<Checkbox size="small" onChange={handleChecked} checked={localState.ageImpactofonAcResolution} name="ageImpactofonAcResolution" />}
                      label="Age Impact of on A/C resolution"
                    />
                    <FormControlLabel className={classes.permission}
                      id="EU_unReadNotifications"
                      control={<Checkbox size="small" onChange={handleChecked} checked={localState.unReadNotifications} name="unReadNotifications" />}
                      label="Unread Notifications"
                    />
                  </FormGroup>
                </FormControl>
              </Box>
            </Grid>
          </Grid >
          <DialogActions>
            <Button id="EU_Submit_btn" startIcon={<DoneAllIcon />} onClick={(_) => { handleSave(); }} color="primary" variant="contained"
              disabled={(!localState.firstName || !localState.lastName || !localState.userName) || isDisable || (!cloudfileError?.length ? false : true)} className={classes.submitbtn}>
              Update
            </Button>
            <Button id="EU_Close_btn" startIcon={<CancelIcon />} onClick={handleClose} className={classes.cancelbtn} color="primary" variant="contained" >
              Close
            </Button>
          </DialogActions>
        </DialogContent >
      </Dialog >
      <Snackbar className="snackBarStyle" open={submit} anchorOrigin={{
        vertical: 'top', horizontal: 'center'
      }} autoHideDuration={4000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity="success" className="alertStyle">
          {localState.firstName} Details Updated Successfully.
        </Alert>
      </Snackbar>
      <Snackbar className="snackBarStyle" open={phone} anchorOrigin={{
        vertical: 'top', horizontal: 'center'
      }} autoHideDuration={4000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
          {localState.firstName} Phone Number Removed Successfully!
        </Alert>
      </Snackbar>
      <Snackbar className="snackBarStyle" open={ClientErrMsg} anchorOrigin={{
        vertical: 'top', horizontal: 'center'
      }} autoHideDuration={4000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity="error" className="alertStyle">
          Please select atleast one client code.
        </Alert>
      </Snackbar>
      <Dialog TransitionComponent={Transition}
        fullScreen={fullScreen} open={cloudFileFolderOpen} onClose={handleAlertClose} maxWidth={'md'}
        aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
        <DialogTitleDelete id="responsive-dialog-title" onClose={handleAlertClose}>
          <Typography variant="h5" gutterBottom className={classes.titleHeader}>
            CLOUD FILE CONTAINS EXAMPLE REGEX
          </Typography>
        </DialogTitleDelete>
        <DialogContent>
          <DialogContentText className={classes.dialogContentTextStyle}>
            <span className={classes.MessageStyle} >
              <Box display="flex" className={classes.boxStyle1}>
                <Box width="70%">
                  1. Regex Format : (Client Code) AND (_1 or _2 or _3 or _4) <br />
                  Example Regex : <b style={{ color: 'green' }}>[\w]*START_REPLACE[\w]*_(END_REPLACE) </b><br />
                </Box>
                <Box width="20%">
                  <LightTooltip title="Click here to Validate Regex">
                    <IconButton style={{ marginLeft: '80px' }} color="secondary" aria-label="upload picture" component="span" onClick={() => window.open(constant.validateRegex)}>
                      <InfoIcon />
                    </IconButton>
                  </LightTooltip>
                </Box>
              </Box>
              <Box className={classes.boxStyle}>
                2. Regex Format : (1STP_HYNEXT) or (CONT_HYNEXT) or (PREC_HYNEXT) <br />
                Example Regex : <b style={{ color: 'green' }}>[\w]*_(END_REPLACE) </b><br />
              </Box>
              <Box className={classes.boxStyle}>
                3. Regex Format : (Client Code1) OR (Client Code2) OR (Client Code3) OR (Client Code4) OR (Client Code5) OR (Client Code6) OR (Client Code7) OR (Client Code8) OR (Client Code9) AND ( _1 OR _2 OR _3 OR _4) <br />
                Example Regex : <b style={{ color: 'green' }}>[\w]*(START_WORDS_SEP_BY_PIPE)[\w]*_(ONE_TO_NINE_SEP_BY_PIPE) </b><br />
              </Box>
              <Box className={classes.boxStyle}>
                4. Regex Format : (GGP001 OR GGP003 OR GGP004 OR GGP006 OR GGP007 OR GGP010 OR GGP011 OR GGP012 OR GGP015) and (_1 or _2 or _3 or _4) <br />
                Example Regex : <b style={{ color: 'green' }}>[\w]*(START_WORDS_SEP_BY_PIPE)[\w]*_(ONE_TO_NINE_SEP_BY_PIPE)  </b><br />
              </Box>
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions >
          <Button id="DU_ok_btn" variant="contained" onClick={(e) => { setCloudFileFolderOpen(false) }} className={classes.yesCrtEtButton}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog TransitionComponent={Transition}
        fullScreen={fullScreen} open={paymentFileFolder} onClose={handleAlertClose} maxWidth={'md'}
        aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
        <DialogTitleDelete id="responsive-dialog-title" onClose={handleAlertClose}>
          <Typography variant="h5" gutterBottom className={classes.titleHeader}>
            PAYMENT FILE CONTAINS EXAMPLE REGEX
          </Typography>
        </DialogTitleDelete>
        <DialogContent>
          <DialogContentText className={classes.dialogContentTextStyle}>
            <span className={classes.MessageStyle} >
              <Box display="flex" className={classes.boxStyle1}>
                <Box width="70%">
                  1. Regex Format : CLIENT1 or CLIENT2 or EXSSTE <br />
                  Example Regex : <b style={{ color: 'green' }}>CLIENT1 or CLIENT2 or EXSSTE	[\w]*START_WORDS_SEP_BY_PIPE[\w]*	</b> <br />
                </Box>
                <Box width="20%">
                  <LightTooltip title="Click here to Validate Regex">
                    <IconButton style={{ marginLeft: '80px' }} color="secondary" aria-label="upload picture" component="span" onClick={() => window.open(constant.validateRegex)}>
                      <InfoIcon />
                    </IconButton>
                  </LightTooltip>
                </Box>
              </Box>
              <Box className={classes.boxStyle}>
                2. Regex Format : (STE999) or (STEHAR) or (shred) or (stericycle) <br />
                Example Regex : <b style={{ color: 'green' }}>[\w]*START_WORDS_SEP_BY_PIPE[\w]* </b><br />
              </Box>
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions >
          <Button id="DU_ok_btn" size="small" variant="contained" onClick={(e) => { setPaymentFileFolder(false) }} className={classes.yesCrtEtButton}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog TransitionComponent={Transition}
        fullScreen={fullScreen} open={cloudFile} onClose={handleCloudDialogCLose} maxWidth={'xs'}
        aria-labelledby="responsive-dialog-title" PaperProps={{ style: { borderRadius: 10 } }} >
        <DialogTitleDelete id="responsive-dialog-title" onClose={handleCloudDialogCLose}>
          <Typography variant="h6" gutterBottom className={classes.titleheader}>
            CLOUD FOLDER CONFORMATION
          </Typography>
        </DialogTitleDelete>
        <DialogContent>
          <DialogTitle className={classes.titleHeader}>
            <img src={cloudfile} alt="Block_Email" className={classes.Image} />
          </DialogTitle>
          <Typography variant="h6" gutterBottom className={classes.textfield1}>
            Are you sure, want to change cloud file folder?
          </Typography>
        </DialogContent>
        <Box display="flex" alignItems="center" justifyContent="center" className={classes.dialogaction}>
          <Button id="IM_ok_btn" startIcon={<CheckCircleIcon />} size="small" variant="contained" className={classes.yesButton1} onClick={(e) => { HandleUpdateContainer(e, isCloudFolder); }} color="primary" autoFocus>
            Yes
          </Button>
          <Button id="IM_Cancel_Btn" startIcon={<CancelIcon />} size="small" variant="contained" autoFocus className={classes.noButton1} onClick={() => { handleCloudDialogCLose(); }} color="primary">
            No
          </Button>
        </Box>
      </Dialog>

    </React.Fragment >
  );
}

export default GA_EditUser